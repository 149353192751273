import React from "react";
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import commitLeft from '../../assets/image/commit-left.png';
import commitRight from '../../assets/image/commit-right.png';

const Commit  = ({ className, ...rest }) => {

  return (
<ParallaxProvider>   
    <div className={className} {...rest}>   
      <div className="pt-25 pb-25 text-center grey position-relative">
            <Parallax className="d-none d-md-block absolute" style={{zIndex:"0", left:"-200px"}} translateY={['-450px', '0px']} translateX={['0px', '0px']}>
              <img className="w-50 w-lg-100" src={commitLeft} alt="left design" />
            </Parallax>
            <Parallax className="d-none d-md-block absolute" style={{zIndex:"0", right:"-200px"}} translateY={['-100px', '-500px']} translateX={['0px', '0px']}>
              <img className="w-50 w-lg-100" src={commitRight} alt="right design" />
            </Parallax> 
            <Parallax className="d-block d-md-none absolute" style={{zIndex:"0", left:"-375px"}} translateY={['-250px', '0px']} translateX={['0px', '0px']}>
              <img className="w-50 w-lg-100" src={commitLeft} alt="left design" />
            </Parallax>                   
            <Parallax className="d-block d-md-none absolute" style={{zIndex:"0", right:"-425px"}} translateY={['-100px', '-200px']} translateX={['0px', '0px']}>
              <img className="w-50 w-lg-100" src={commitRight} alt="right design" />
            </Parallax>                  
            <div className="flex justify-content-center" style={{flexDirection:"column",position:"relative", zIndex:"2"}}>
                <h4 className="h3">Ready for help?</h4>
                <p>Try a Digma Developer today!</p>
                <a href="/contact" className="m-auto btn text-white btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3">Get in touch</a>
            </div>          
      </div>
    </div>
    </ParallaxProvider>
  );
};

export default Commit;
