import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import imgb from '../../assets/image/assist.webp';
import imgDP from '../../assets/image/home-2/png/dot-pattern-black.png';

const Content = () => {
  return (
    <div className="pb-5 pb-md-5 pb-lg-5">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* Right */}
          <div className="order-1 col-xl-5 col-lg-5 col-md-5 col-xs-10">
            <div className="about-content-image-group-1 mb-8 mb-lg-0">
              <h3 className="">How it works</h3>
              <div className="img-1">
                <img
                  className="w-100 rounded-10"
                  src={imgb}
                  alt="Digma Mobile Interactive video"
                  data-aos="fade-right"
                  data-aos-duration={600}
                />
                <div className="img-2 z-index-n1 mt-n13">
                  <img
                    className="w-100"
                    src={imgDP}
                    alt="Spotted pattern"
                    data-aos="fade-right"
                    data-aos-duration={900}
                    data-aos-delay={300}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Right */}
          {/* Left */}
          <div className="order-2 col-xl-6 col-lg-7 col-md-7 col-xs-10">
            <div
              className="mx-lg-14 mx-xl-14"
              data-aos="fade-left"
              data-aos-duration={600}
            >
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
                <span className="h5 Videos align-content-center">
                  01 - Submit your request
                </span>
                Ready to use the Digma software? Whether you require a new
                interactive video build or a stunning interactive image designed
                for your website, submit your request and we’ll assign one of
                our Digma Developers right away and offer you a quotation.{' '}
              </p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
                <span className="h5 Videos align-content-center">
                  02 - Discuss your needs
                </span>
                Once approved, your Developer will get in touch to discuss your
                project and expectations, and offer recommendations to get the
                best from your interactivity.{' '}
              </p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
                <span className="h5 Videos align-content-center">
                  03 - Get your project done
                </span>
                Time to get to work! Together, we’ll arrange a project timeline,
                verify receipt of any company assets that’ll be used and bring
                your ideas and project to life, ready to upload.{' '}
              </p>
            </div>
          </div>
          {/* Left End */}
          {/* Left */}
          <div className="order-3 col-xl-12 col-lg-12 col-md-12 col-xs-12">
            <div data-aos="fade-left" data-aos-duration={600}>
              <h3 className="mt-20 text-center">What can a Developer do?</h3>
              <p className="heading-default-color font-size-6 mb-5 text-center">
                All our developers are Digma specialists and know the platform
                inside out!
                <br />
                This means they can help with all aspects of the Digma product
                suite.
              </p>
              <div className="row justify-content-center">
                <div className="rounded-5 bg-video-light p-10 m-5 col-xl-5 col-lg-5 col-md-10 col-xs-10">
                  <span className="h5 Videos">Interactive Video</span>
                  <p className="heading-default-color font-size-6">
                    From simple to complex projects, our developers can remove
                    all the design and build effort and transform your MP4 into
                    a ready-to-use interactive asset.
                  </p>
                  <AniLink
                    className="btn bg-video text-white rounded-5 btn-xl h-55"
                    paintDrip
                    hex="#2196f3"
                    to="/interactive-video-player"
                  >
                    Learn more
                  </AniLink>
                </div>
                <div className="rounded-5 bg-image-light p-10 m-5 col-xl-5 col-lg-5 col-md-10 col-xs-10">
                  <span className="h5 Images">Interactive Image</span>
                  <p className="heading-default-color font-size-6">
                    1, 2, 15 or 1000? Whatever the project size, our developers
                    will be on hand to take on the workload your company
                    requires. Upon project approval, we’ll send you all the
                    relevant asset code!
                  </p>
                  <AniLink
                    className="btn bg-image text-white rounded-5 btn-xl h-55"
                    paintDrip
                    hex="#ff9b04"
                    to="/interactive-images"
                  >
                    Learn more
                  </AniLink>
                </div>
                <div className="rounded-5 bg-link-light p-10 m-5 col-xl-5 col-lg-5 col-md-10 col-xs-10">
                  <span className="h5 Links">Linking &amp; QR Codes</span>
                  <p className="heading-default-color font-size-6">
                    Often used for bulk linking projects, our developers can
                    transform your link data into fully trackable short links
                    and QR codes, ready to use throughout your print, digital
                    and online marketing.
                  </p>
                  <AniLink
                    className="btn bg-link text-white rounded-5 btn-xl h-55"
                    paintDrip
                    hex="#e80ad2"
                    to="/qr-codes"
                  >
                    Learn more
                  </AniLink>
                </div>
                <div className="rounded-5 bg-form-light p-10 m-5 col-xl-5 col-lg-5 col-md-10 col-xs-10">
                  <span className="h5 Forms">Forms</span>
                  <p className="heading-default-color font-size-6">
                    Our developers can design and generate responsive forms for
                    your interactive videos or images, or even for your website!
                    We’ll set up user response capture which is saved and easily
                    exported at the touch of a button.
                  </p>
                  <AniLink
                    className="btn bg-form text-white rounded-5 btn-xl h-55"
                    paintDrip
                    hex="#25b401"
                    to="/form-builder"
                  >
                    Learn more
                  </AniLink>
                </div>
              </div>
            </div>
          </div>
          {/* Left End */}
        </div>
      </div>
    </div>
  );
};

export default Content;
