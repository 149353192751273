import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/assist/Hero";
import Content from "../sections/assist/Content";
import Commit from "../sections/common/Assist";
import SEO from '../components/SEO';
import imgC from "../assets/image/inner-page/jpg/cooking.jpg";

const PricingPage = () => {

  // Example of dynamically using location prop as a crumbLabel
  // NOTE: this code will not work for every use case, and is only an example
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Digma Developers For Hire"
          description="Save time and money while ensuring quality with a trusted Digma Developer"
          image={imgC}
        />
        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
            <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info">Info</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>              
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/about">About</Link>
              </li>
              </ol>
           </nav>
        </div>
        <Hero />
        <Content />
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>
        </PageWrapper>
    </>
  );
};
export default PricingPage;
